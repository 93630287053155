<template>
    <div class="row">
        <div class="col-12" v-if="!isLoad">
            <div v-if="exist" class="row">
                <div class="col-12" v-if="questionnaire != null">
                    <div class="py-5 card bg-white border-0 shadow-xss rounded-lg overflow-hidden">
                        <div class="d-flex flex-row  border-0 w-100 p-0 mb-3 shadow-none">
                            <div class="mx-4 my-1 p-0 ml-1 btn-round-md rounded-xl bg-current">
                                <i class="fas fa-clipboard-check font-xl text-white"></i>
                            </div>
                            <div class="card-body p-0 pb-2 border-bottom border-dark">
                                <div class="row justify-content-between col-md-12">
                                    <h6 class="fw-600 text-grey-500 font-xssss">{{questionnaire.updated_at | formatDate}}</h6>
                                    <h6 class="fw-700 text-grey-700 font-xssss">Created by : {{questionnaire.created.name}}</h6>
                                </div>
                                <div class="row justify-content-between col-md-12">
                                    <h2 class="font-lg text-grey-900 fw-600">{{questionnaire.title}}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-11 ml-5 pl-5">
                            <h4 class="text-grey-800 font-xs mt-4 fw-700">Description :</h4>
                            <!-- <p class="font-xsss fw-600 lh-28 text-grey-700 mb-0 pl-0">Time Given : 09:30 - 11:30</p><br> -->
                            <p class="font-xsss fw-600 lh-28 text-grey-600 mb-0 pl-0">{{questionnaire.description}}</p>
                            <router-link v-if="questionnaire.submission == null" :to="{name:'MyCourseReflectionRatingStart', params:{idCourse : paramsId, idQuestionnaire: questionnaire.id}}" class="bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w200">Start</router-link>
                            <div v-else  class="bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-inline-block mt-4 p-2 lh-34 text-center ls-3 w200">Finished</div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8 text-center default-page">
                            <div class="card border-0 text-center d-block">
                                <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                                <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                                <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                                <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" v-else>
            <div class="row">
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            paramsId: this.$route.params.idCourse,
            questionnaireId: this.$route.params.idQuestionnaire,
            isLoad: true,
            exist: true,
            questionnaire: null,
        }   
    },
    created(){
        this.getQuestionnaire()
    },
    methods:{
        async getQuestionnaire(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/reflection/questionnaire/detail?slug=${this.paramsId}&id=${this.questionnaireId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    this.questionnaire = res.data.data
                    this.isLoad = false
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Rating Scale!',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(err => {
                if (err.response.status == 404) {
                    this.isLoad = false
                    this.exist = false
                }
            })
        }
    },
}
</script>